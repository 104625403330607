import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../../../environments/environment';
import { AssociatedAccounts } from '../../../../shared/interfaces/userDetails.interface';
import { accountDetails } from '../../../account/interfaces/user-details.interface';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  constructor(private http: HttpClient) { }
  skipRedirection = false;
  getShippingAddresses(
    accountId: string
  ): Observable<{ shipto: AssociatedAccounts[] }> {
    const url = `${environment.API_BE_ENDPOINT}/auth/accounts/${accountId}/shiptos`;
    return this.http.get<{ shipto: AssociatedAccounts[] }>(url);
  }

  revokeAccessForAll(userId: string | undefined) {
    const url = `${environment.API_BE_ENDPOINT}/auth/users/${userId}/entitlements`;
    return this.http.delete(url);
  }

  revokeAccess(
    userId: string | undefined,
    accountId: string,
    practiceOrg: string
  ) {
    const url = `${environment.API_BE_ENDPOINT}/auth/users/${userId}/account/${accountId}/entitlement?practiceOrgId=${practiceOrg}`;
    return this.http.delete(url);
  }

  searchAccount(
    emailID: string | undefined,
    sapId: string | undefined,
    practiceOrg: string | undefined
  ): Observable<accountDetails | undefined> {
    const url = `${environment.API_BE_ENDPOINT}/auth/accounts/accountDetails/${sapId}?email=${emailID}&practiceOrgId=${practiceOrg}`;
    return this.http.get<accountDetails>(url);
  }
  updateAccount(
    emailID: string,
    sapId: string,
    accessType: string,
    practiceOrg: string
  ) {
    const url = `${environment.API_BE_ENDPOINT}/auth/users/${emailID}/account/${sapId}/entitlement?practiceOrgId=${practiceOrg}`;
    return this.http.post(url, { accessType: accessType });
  }
}
