import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { LocalStorageKey } from '../../../../shared/constants/local-storage-key.const';
import { UserDetails } from '@alcon/alcon-account-selector/lib/account-selector.interface';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private http: HttpClient) {}

  getUserDetails(
    order: string,
    pageSize: number,
    page: number,
    selectedAccountIds?: Array<string>,
    label?: string,
    searchText?: string
  ) {
    const url = `${environment.API_BE_ENDPOINT}/auth/accounts/users`;
    const idsString = selectedAccountIds?.join(',');

    const params: any = {
      sort: order,
      pageSize: pageSize,
      page: page,
      ...(idsString && { selectedAccountIds: idsString }), // Add selectedAccountIds if it exists
    };
    if (label && searchText) {
      params[label] = searchText;
    }
    return this.http.get(url, {
      params: params,
    });
  }

  getUserDetailByParams(
    params: any,
    practiceOrgId: string | undefined
  ): Observable<UserDetails | undefined> {
    const accountNumber = params?.accountNumber
      ? params?.accountNumber
      : localStorage.getItem(LocalStorageKey.SELECTED_ACCOUNT_ID);
    const email = params?.email;

    let url = `${environment.API_BE_ENDPOINT}/auth/users/${email}/account/${accountNumber}/entitlement`;
    if (practiceOrgId) {
      url += `?practiceOrgId=${practiceOrgId}`;
    }
    return this.http.get<UserDetails | undefined>(url);
  }

  getAssociatedFacilities(practiceOrgId: string): Observable<any> {
    let url = `${environment.API_BE_ENDPOINT}/auth/facility/${practiceOrgId}?manageSchedule=true`;

    return this.http.get<any>(url);
  }
}
